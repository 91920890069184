
// To support jQuery plugins
window.jQuery = window.$ = require('jquery');

// Global picture/srcset polyfill
require('picturefill');

// Load our page-level modules (for dependency inspection/loading)
require('pages/home');
require('pages/work');
require('pages/etc');
require('pages/team');

// Execute common code here.
var common = require('common');

initModule(common);


// Now, inspect the body element and see what page module is there.
// If one exists, initialize the page script it references.
// NOTE: value of 'data-page-module' attribute on body tag should
// match name of module.
var body = document.body;

if (body.hasAttribute('data-page-module')) {
    var pageModuleName = body.getAttribute('data-page-module'),
        pageModule = (pageModuleName !== '') ? require('pages/' + pageModuleName) : null;

    initModule(pageModule);
}

function initModule(mod) {
    if ((mod !== null) && (typeof mod !== 'undefined') && (typeof mod.init === 'function')) {
        mod.init();
    }
}
